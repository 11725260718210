@tailwind base;
@tailwind components;
@tailwind utilities;

/*@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');*/

body {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.google.com/specimen/Lexend)
}

form input {
  font-family: 'Abel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE2V9BPbh5uGM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Yeni yüklenen sayfalar için stil */
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Sayfa yüklendikten sonra opacity: 1 olacak */
.fade-in.active {
  opacity: 1;
}